<div class="slack-block">
  <ng-container [ngSwitch]="block.type">
    <ng-container *ngSwitchCase="'section'">
      <div class="slack-section-block">
        <ng-container [ngSwitch]="block.text.type">
          <ng-container *ngSwitchCase="'plain_text'">
            <span class="slack-block-text">{{ block.text.text }}</span>
          </ng-container>

          <ng-container *ngSwitchCase="'mrkdwn'">
            <span [innerHTML]="toHtml(block.text.text)"></span>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="block.accessory">
          <div
            class="slack-section-block-image"
            style="background-image: url({{ block.accessory.image_url }});"
            title="{{ block.accessory.alt_text }}"
          ></div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'actions'">
      <ion-button
        *ngFor="let button of block.elements"
        class="btn-small"
        [color]="button.style === 'primary' ? 'success' : 'dark'"
        [fill]="button.style === 'primary' ? 'solid' : 'outline'"
      >
        {{ button.text.text }}
      </ion-button>
    </ng-container>

    <ng-container *ngSwitchCase="'image'">
      <img
        class="slack-image-block"
        src="{{ block.image_url }}"
        alt="{{ block.alt_text }}"
        title="{{ block.alt_text }}"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'divider'">
      <div class="slack-divider-block"></div>
    </ng-container>

    <ng-container *ngSwitchCase="'header'">
      <div class="slack-header-block">
        <h3>{{ block.text.text }}</h3>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'context'">
      <div class="slack-context-block">
        <ng-container *ngFor="let element of block.elements">
          <div
            *ngIf="element.type !== 'image'"
            [innerHTML]="toHtml(element.text)"
            class="slack-context-block-text"
          ></div>
          <div
            *ngIf="element.type === 'image'"
            class="slack-context-block-image"
            style="background-image: url({{ element.image_url }});"
            title="{{ element.alt_text }}"
          ></div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'survey'">
      <ng-container *ngIf="block.survey.buttonLayout === 'inline'">
        <ng-container *ngIf="block.survey.selectionOptions === 'emojiFaces'">
          <ion-button class="btn-small" fill="outline" color="dark"> 🤩 </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark"> 🙂 </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark"> 😐</ion-button>
          <ion-button class="btn-small" fill="outline" color="dark"> 😞 </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark"> 😡</ion-button>
        </ng-container>
        <ng-container *ngIf="block.survey.selectionOptions !== 'emojiFaces'">
          <ion-button class="btn-small" fill="outline" color="dark"> 1️⃣ </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark"> 2️⃣ </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark"> 3️⃣ </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark"> 4️⃣ </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark"> 5️⃣ </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark" *ngIf="block.survey.type === 'nps'">
            6️⃣
          </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark" *ngIf="block.survey.type === 'nps'">
            7️⃣
          </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark" *ngIf="block.survey.type === 'nps'">
            8️⃣
          </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark" *ngIf="block.survey.type === 'nps'">
            9️⃣
          </ion-button>
          <ion-button class="btn-small" fill="outline" color="dark" *ngIf="block.survey.type === 'nps'">
            🔟
          </ion-button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="block.survey.buttonLayout !== 'inline'">
        <ion-button class="btn-small" fill="outline" color="dark">
          {{ block.survey.buttonLabel }}
        </ion-button>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
