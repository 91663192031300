import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { SlackMarkdown } from '../slack-markdown';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule],
  selector: 'app-slack-block-preview',
  templateUrl: './slack-block-preview.component.html',
  styleUrls: ['./slack-block-preview.component.scss'],
})
export class SlackBlockPreviewComponent {
  @Input() block: any;
  @Input() slackMarkdown: SlackMarkdown;

  toHtml(text: string): string {
    return this.slackMarkdown.toHtml(text);
  }
}
