import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { SharedPipesModule } from 'src/pipes/shared-pipes.module';

@Component({
  standalone: true,
  imports: [CommonModule, SharedPipesModule],
  selector: 'app-slack-message',
  templateUrl: './slack-message.component.html',
  styleUrls: ['./slack-message.component.css'],
})
export class SlackMessageComponent {
  @Input() content: string;
}
