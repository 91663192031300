<ion-header class="modal-header">
  <div class="modal-title">
    <h3>Choose a Gif</h3>
  </div>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear" shape="round" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-searchbar
    mode="ios"
    [(ngModel)]="searchValue"
    (ionChange)="getGifs()"
    (ionClear)="getGifs()"
    (debounce)="(1000)"
    placeholder="Search for a gif..."
    style="padding: 0 20px"
    showClearButton="always"
    #searchInput
  >
  </ion-searchbar>
  <div class="container">
    <ion-card class="outline-card clickable" *ngFor="let gif of gifs">
      <img
        class="image"
        src="{{ gif.images.downsized.url }}"
        (click)="selectGif(gif.images.downsized.url, gif.title)"
      />
    </ion-card>
  </div>
</ion-content>
